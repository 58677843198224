// This file converts the doctor's data into a csv
import { formsLookUp } from "../models/forms"

const apntmntArraysWithObjects = {
  biodata: {
  },
  presenting_complaints: {
    complaints: [{
      complaint: "",
      duration: ""
    }], //contains complaint objects
  },
  review_of_systems: {
    cardiorespiratory: [],
    gastrointestinal: [],
    genitourinary: [],
    endocrine: [],
    notes: ""
  },
  past_medical_history: {
    hospitalizations: [{
      diagnosis: "", duration: 0, facility: "", treatment: "", recovery: ""
    }], //array of hospitalization(s)
    surgeries: [{
      diagnosis: "", duration: 0, facility: "", treatment: "", recovery: ""
    }],
    blood_transfusions: [{ pints: 0, donor: "", facility: "", reaction: "" }],
    comorbidities: [{
      age_diagnosed: "",
      comorbidity: "",
      year_diagnosed: 0,   //number
      duration: 0,    //number
      treatment: "", compliance: "", response: ""
    }],
    blood_group: "",
    rhesus: "",
    genotype: "",
    notes: ""
  },
  drugs: [{ name: "", dosage: "", usage: "", reaction: "" }],
  allergies: [{
    substance: "", reaction: ""
  }],
  drugs_and_allergies_notes: "",
  family_history: [],
  alcohol: {
    alcoholtype: [],
    alcoholbottlesperweek: "",   //number
    alcoholbottlesize: "",   //number
    alcoholconsumptionduration: ""   //number
  },
  cigarette: { cigarettesticksperday: "", cigarettesmokingduration: "" },
  fshx_notes: "",
  summary: "",
  general: { onexamination: [], notes: "" },
  neuro: {
    highermentalfunctions: "", cranialnerves: "", motor_sides: [], motor_limbs: [],
    motor_inspection: [], motor_tone: "", motor_power: "", motor_reflexes: "", ankle_clonus: "",
    babinski: "", sensorysides: [], sensorylimbs: [], sensoryfinetouch: "", sensorycoarsetouch: "",
    sensorytemperature: "", sensoryvibration: "", sensoryproprioception: "", sensorypressure: "",
    gaitandcoordination: "", notes: ""
  },
  cvs: { notes: "" }, chest: { notes: "" }, abdomen: { notes: "" }, others: { notes: "" },
  imaging: { tests: [], notes: "" }, electrical: { tests: [], notes: "" }, haematology: { tests: [], notes: "" },
  labs: { tests: [], notes: "" }, microbiology: { tests: [], notes: "" }, procedures: { tests: [], notes: "" },
  pharmacological: { notes: "" }, nonpharmacological: { notes: "" }, other: { notes: "" },
  assessment: {
    notes: ""
  },
  plan: {
    notes: ""
  },
  date_seen: "",
  next_visit: "",
  monitoring: {
    markers: [],
    notes: ""
  },
  forms: { ...formsLookUp, epilepsy: {} },
  notes: ""
}

// Object stores the lengths of the appointment data with arrays for initialisation
// down to line to make sure the resulting column names align with values immediately
// without requiring further parsing.
const arrayLengths = {};

// returns appointment fields which are arrays that contain strings (as opposed to objects)
// and require concatenation
function isStringifiable(property) {
  return property === "cardiorespiratory" || property === "gastrointestinal" ||
    property === "genitourinary" || property === "endocrine" || property === "family_history" ||
    property === "onexamination" || property === "alcoholtype"
}

function isObjStringifiable(property) {
  return property.includes("complaint") || formsLookUp[property] !== undefined;
}

function processBasedOnType(property, data) {
  // if the data is an array, concatenate the strings by default but for some specific cases,
  // for example containing object items, process it further recursively
  if (Array.isArray(data)) {
    // console.log(`(property) ${property} => (array data)`, data);
    if (data.length === 0) {
      return [[property, ""]]
    } else {
      if (data.length > 0 && typeof data[0] !== "string")
        return data.flatMap((d, i) => processBasedOnType(`${property}_${i + 1}`, d));
      else return [[property, data.join(", ")]]
    }
  }

  if (typeof data === "object") {
    // console.log(`(property) ${property} => (object data)`, data);
    if (isObjStringifiable(property)) {
      return [[property, Object.entries(data).map(([k, v]) => `(${k}) ${v}`).join(", ")]];
    }
    return Object.entries(data)
      .flatMap(([key, value]) => {
        // console.log(`${property}_${key} => (${typeof value})`, value);
        return processBasedOnType(`${property}_${key}`, value);
      })
    // return Object.keys(data).map(d => `${d}: ${processBasedOnType(data[d])}`).join(", ");
  }

  if (typeof data === "string") return [[property, data.replace(/\s/g, " ").replace(/;/g, "")]];

  if (typeof data === "undefined") return [[property, ""]]
  return [[property, data]];
}

export function dataExporterHelper(patients, fields) {
  // @param patients: an array of patient object data
  // @param fields: an object with string values for each property e.g {biodata: 'firstname'}

  delete fields.downloadFile;
  return patients.flatMap((patient, index) => {
    return patient.appointment_keys.sort((a, b) => a - b).map((apntmntKey, index2) => {
      const patientForExport = {};
      // We're only picking the first appointment here but we need to export all appointments
      const apntmnt = patient[apntmntKey];
      patientForExport._id = patient._id; // export patient id
      patientForExport.appointment_id = apntmntKey;
      patientForExport.visit_number = index2 + 1;

      const fieldsKeys = Object.keys(fields);
      for (let f = 0; f < fieldsKeys.length; f++) {
        const key = fieldsKeys[f];
        const fieldsString = fields[key]; // 
        const field = fieldsString ? fieldsString.split(", ") : []; // field is an array of strings
        // field array contains sections chosen for export

        // patientForExport[field] = 

        for (let i = 0; i < field.length; i++) {
          const property = field[i];
          // console.log("apntmnt[fieldsKeys[f]] => ", apntmnt[fieldsKeys[f]]);
          // console.log("apntmnt[field] => ", apntmnt[field]);
          // console.log("property => ", property);
          let fieldValue = key !== "others" ? apntmnt[key][property] : apntmnt[property];

          if (Array.isArray(fieldValue)) {
            if (index === 0) {
              patients.forEach(p => {
                p.appointment_keys.forEach(k => {
                  const apt = p[k];
                  // find maximum length
                  const length = apt[key][property].length;
                  if (arrayLengths[property] === undefined || length > arrayLengths[property]) {
                    arrayLengths[property] = length;
                  }
                })
              })
            }
            // console.log("arrayLengths =>", arrayLengths);
            const arrayObject = apntmntArraysWithObjects[key][property];
            // console.log("arrayObject =>", arrayObject);
            const hasObject = arrayObject.length === 1;
            if (hasObject) { 
              const qty = arrayLengths[property];
              const array = new Array(qty).fill(arrayObject[0]);
              fieldValue = array.map((a, i) => i < fieldValue.length ? fieldValue[i] : a);
              // console.log("new array items for", property, " =>", fieldValue);
              // fieldValue.splice(-1, 0, ...array);
            }
          }

          const obj = processBasedOnType(property, fieldValue);
          // console.log("Now printing keys for", property);
          // console.log(property, "values =>", obj)
          obj.forEach(([k, v]) => {
            // console.log(`(patientForExport) ${k} => ${v}`)
            patientForExport[k] = v
          });
        }
      }

      // patientForExport.complaints = apntmnt.presenting_complaints.complaints
      // .map(complaint => complaint.complaint.concat(" x ", complaint.duration)).join();

      // console.log(patient);

      const apt_notes = processBasedOnType("appointment_notes", apntmnt.notes);
      // console.log("apntmnt notes => ", apt_notes[0][1]);
      patientForExport.appointment_notes = apt_notes[0][1];
      patientForExport.primary_diagnosis = patient.primary_diagnosis;
      patientForExport.secondary_diagnosis = patient.secondary_diagnosis;

      const values = Object.values(patientForExport).join(';');
      if (index === 0 && index2 === 0)
        return Object.keys(patientForExport).join(';').concat("\r\n", values);
      // console.log("values => ", values);
      return values;
    })
  })
}